import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PostList from "../../components/postList"

function EventsPage({ data, location }) {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location}>
      <Seo title="Events on UI and UX design" description="UX Choice events is a collection of latest and upcoming events for designers and creatives" />
      <main>
        <div className="relative px-4 sm:px-6 md:px-8 max-w-screen-xl mx-auto">
          <div className="py-16">
            <h1 className="text-4xl sm:text-6xl lg:text-7xl leading-tight tracking-tight text-gray-900 mb-8 sm:mb-10">
              Events
            </h1>
            <p className="max-w-4xl text-lg sm:text-2xl sm:leading-10 font-medium">
                A curated collection of best podcasts on UI and UX design updated weekly. 
            </p>
          </div>
          <div className="mb-16">
            <PostList posts={posts} />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default EventsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: "events" } } }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          date
          coverImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
